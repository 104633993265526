import React from "react";
import { Link } from "gatsby"

const ExamplesLink = () => (
    <Link to="/examples/"
          style={{
              fontSize: `1.7em`,
              marginTop: `2em`,
              marginBottom: `3em`
          }}
    >
        See some examples
    </Link>
)

export default ExamplesLink
